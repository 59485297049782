<template>
  <b-container>
    <ct-centered-spinner v-if="!loaded" height="16.25rem" />
    <monitoring-instructional-accordion 
      :id="'keyword-collapse'"
      :header="'Keyword Monitoring'" 
      :instructional-text="instructionalContent"
    />
    <div v-if="keywords?.length > 0" class="monitoring-keywords-card">
      <b-table :items="keywords" :fields="fields" class="table">
        <template v-slot:head(value)>Keywords</template>
        <template v-slot:cell(active)="data">
          <div class="active-button">
            <b-form-checkbox v-model="data.item.active" name="check-button" switch size="lg" :disabled="isEditMode" />
          </div>
        </template>
        <template v-slot:cell(value)="data">
          <b-form-tags 
            v-model="data.item.value" 
            separator="," 
            size="lg" 
            :disabled="isEditMode" 
            :limit="keywordLimit"
            limit-tags-text="" 
            class="custom-form-tags" 
            @limit="onLimitReached" 
          />
          <div v-if="isLimitReached || keywordLengthExceeded(data.item.value)" class="text-danger mt-2">
            Keyword limit reached. A total of {{ keywordLimit }} keywords can be used.
            Please add new keyword set if more are needed.
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="manage-buttons">
            <b-button 
              aria-label="Save button" 
              variant="primary" 
              size="sm" 
              class="save-button"
              @click="saveChanges(data.item)"
            >
              {{ buttonText }}
            </b-button>
            <b-button 
              aria-label="Delete button" 
              variant="danger" 
              size="sm" 
              class="delete-button"
              @click="showModal(data.item)"
            >
              Remove
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="add-keyword-button">
        <b-button variant="success" @click="showKeywordModal">Add new Keywords</b-button>
      </div>
    </div>
    <monitoring-no-identity v-else identity-type="Keywords" :model-solution="true" @add-identity="showKeywordModal" />

    <b-modal ref="add-keyword" hide-footer title="Add Keyword(s)">
      <small>Click Add or press enter to add keyword</small>
      <b-form @submit.prevent="addItem">
        <b-form-group>
          <b-form-tags 
            v-model="newItem.value" 
            separator="," 
            size="lg" 
            :limit="keywordLimit" 
            limit-tags-text=""
            class="custom-form-tags" 
            @limit="onLimitReached"
          />
          <div v-if="isLimitReached" class="text-danger mt-2">
            Keyword limit reached. A total of {{ keywordLimit }} keywords can be used. 
            Please add new keyword set if more are needed.
          </div>
        </b-form-group>
        <b-button 
          type="submit" 
          variant="primary" 
          class="mr-2" 
          aria-label="Add button"
          :disabled="newItem.value.length === 0"
        >
          Add
        </b-button>
        <b-button variant="danger" aria-label="Cancel button" @click="hideKeywordModal">Cancel</b-button>
      </b-form>
    </b-modal>
    <b-modal ref="confirm-delete" hide-footer title="Are you sure you want to delete?">
      <b-button class="mt-3" variant="secondary" block aria-label="Cancel button" @click="hideModal">Cancel</b-button>
      <b-button class="mt-2" variant="danger" block aria-label="Confirm button" @click="deleteItem">Confirm</b-button>
    </b-modal>
  </b-container>
</template>


<script>
import MonitoringInstructionalAccordion from '@/components/Monitoring/MonitoringInstructionalAccordion.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import MonitoringNoIdentity from '@/components/Monitoring/MonitoringNoIdentity.vue'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { MonitoringIdentityType } from '@/common/modules/constants'
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "MonitoringOverviewPage",
  components: {
    MonitoringNoIdentity,
    CtCenteredSpinner,
    MonitoringInstructionalAccordion,
  },
  mixins: [makeToastMixin],
  data() {
    return {
      fields: ['active', 'value', 'actions'],
      keywords: [],
      newItem: { value: [], active: true }, // False by default for active
      itemToDelete: {},
      isEditMode: true,
      keywordLimit: 3,
      loaded: false,
      limitReached: false,
      instructionalContent: {
        sections: [
          {
            header: 'Set up a Keyword Identity to monitor',
          },
          {
            generic: 'Keyword Identities are specific words of your choosing that you wish to monitor and appear in your monthly report.',
          },
          {
            generic: 'Click “Add Keywords” below, and input up to 3 keywords.',
            items: [
              '<strong>Declarative word usage:</strong> Keywords are only as good as the words you select. Avoid idle words like "the" or "if,"  and choose words relevant to your services, industry, or location.',
            ],
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('account', ['actualAccountID']),
    ...mapGetters('monitoring', [
      'monitoringIdentitiesByType',
    ]),
    buttonText() {
      return this.isEditMode ? 'Edit' : 'Save'
    },
    formatKeywords() {
      return this.keywords?.map(keyword => ({ value: keyword, text: keyword }))
    },
    isLimitReached() {
      return this.newItem.value.length >= this.keywordLimit
    },
  },
  async mounted() {
    this.loaded = false
    await this.fetchMonitoringIdentitiesByType({ accountID: this.actualAccountID, identityType: MonitoringIdentityType.KEYWORD })
    this.formatKeywordDataFromIdentities()
    this.loaded = true
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringIdentitiesByType',
      'removeMonitoringIdentity',
      'updateMonitoringIdentity',
    ]),
    async addItem() {
      try {
        const keywords = Array.isArray(this.newItem.value) 
          ? this.newItem.value.join(', ') 
          : this.newItem.value

        const itemCopy = {
          ...this.newItem,
          account_id: this.actualAccountID,
          id: uuidv4(),
          value: keywords,
        }

        await this.updateMonitoringIdentity(itemCopy)
        this.keywords.push({ ...itemCopy, value: keywords.split(', ') })
        this.successToast('Success', 'Keyword(s) added')
      } catch (error) {
        this.errorToast('Error', `Failed to add keyword(s) - ${error.error}`)
      } finally {
        this.hideKeywordModal()
      }
    },
    formatKeywordDataFromIdentities() {
      const keywordsData = this.monitoringIdentitiesByType[MonitoringIdentityType.KEYWORD]
      this.keywords = keywordsData?.map(k => k = { ...k, value: k.value.split(', ') })
    },
    keywordLengthExceeded(existingItem) {
      if (existingItem) { return existingItem.length >= this.keywordLimit }
    },
    async saveChanges(item) {
      if (this.isEditMode) {
        this.isEditMode = false
      } else {
        this.isEditMode = true
        try {
          const keywords = Array.isArray(item.value) ? item.value.join(', ') : item.value
          const itemCopy = { ...item, value: keywords } // Shallow copy to avoid adjusting base value
          await this.updateMonitoringIdentity(itemCopy)
          
          const index = this.keywords.findIndex(k => k.id === item.id)
          if (index !== -1) {
            this.$set(this.keywords, index, { ...itemCopy, value: keywords.split(', ') })
          }
          this.successToast('Success', "Keywords(s) saved")
        } catch (error) {
          this.errorToast('Error', `Failed to save keyword(s) - ${error.error}`)
        }
      }
    },
    showModal(item) {
      this.itemToDelete = item
      this.$refs['confirm-delete'].show()
    },
    hideModal() {
      this.saveItem = {}
      this.$refs['confirm-delete'].hide()
    },
    showKeywordModal() {
      this.newItem = { value: [], active: true, type: MonitoringIdentityType.KEYWORD }
      this.$refs['add-keyword'].show()
    },
    hideKeywordModal() {
      this.$refs['add-keyword'].hide()
    },
    onLimitReached() {
      this.limitReached = true
    },
    async deleteItem() {
      let item = this.itemToDelete
      try {
        await this.removeMonitoringIdentity({ monitoringIdentityID: item.id, accountID: this.actualAccountID })
        const index = this.keywords.findIndex(keyword => keyword.id === item.id)
        if (index > -1) {
          this.keywords.splice(index, 1)
        }
        this.successToast('Success', "Keyword(s) deleted")
      } catch {
        this.errorToast('Error', "Keyword(s) failed to delete, please try again")
      } finally {
        this.hideModal()
      }
    },
  },
}
</script>

<style lang="scss">
.monitoring-keywords-card {
  .table {
    border: 1px solid #cccee0;
    border-radius: 0.5em;
    background-color: white;
  }

  .add-keyword-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 1em;
  }

  .edit-button {
    margin-left: 1rem;
  }

  .active-button {
    margin-top: .6rem;
    margin-left: .5rem;
  }
}

.text-danger {
  color: red;
}

.custom-form-tags .b-form-tags {
  font-size: 1.25rem;
}

.custom-form-tags .b-form-tag {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

td ul.list-unstyled {
  display: flex;
  gap: 1rem;
}

td li.badge {
  margin: 0;
}

.add-identity-button button {
  width: 22rem;
}

.manage-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-right: 1rem;
  max-width: 7rem;
}

.manage-buttons>button {
  width: 100%;
  margin: 0 !important;
}

.table td {
  vertical-align: middle;
}

.table .custom-switch {
  padding-bottom: 0.4em;
}
</style>
