var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      !_vm.loaded
        ? _c("ct-centered-spinner", { attrs: { height: "16.25rem" } })
        : _vm._e(),
      _c("monitoring-instructional-accordion", {
        attrs: {
          id: "keyword-collapse",
          header: "Keyword Monitoring",
          "instructional-text": _vm.instructionalContent,
        },
      }),
      _vm.keywords?.length > 0
        ? _c(
            "div",
            { staticClass: "monitoring-keywords-card" },
            [
              _c("b-table", {
                staticClass: "table",
                attrs: { items: _vm.keywords, fields: _vm.fields },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "head(value)",
                      fn: function () {
                        return [_vm._v("Keywords")]
                      },
                      proxy: true,
                    },
                    {
                      key: "cell(active)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "active-button" },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  name: "check-button",
                                  switch: "",
                                  size: "lg",
                                  disabled: _vm.isEditMode,
                                },
                                model: {
                                  value: data.item.active,
                                  callback: function ($$v) {
                                    _vm.$set(data.item, "active", $$v)
                                  },
                                  expression: "data.item.active",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(value)",
                      fn: function (data) {
                        return [
                          _c("b-form-tags", {
                            staticClass: "custom-form-tags",
                            attrs: {
                              separator: ",",
                              size: "lg",
                              disabled: _vm.isEditMode,
                              limit: _vm.keywordLimit,
                              "limit-tags-text": "",
                            },
                            on: { limit: _vm.onLimitReached },
                            model: {
                              value: data.item.value,
                              callback: function ($$v) {
                                _vm.$set(data.item, "value", $$v)
                              },
                              expression: "data.item.value",
                            },
                          }),
                          _vm.isLimitReached ||
                          _vm.keywordLengthExceeded(data.item.value)
                            ? _c("div", { staticClass: "text-danger mt-2" }, [
                                _vm._v(
                                  "\n          Keyword limit reached. A total of " +
                                    _vm._s(_vm.keywordLimit) +
                                    " keywords can be used.\n          Please add new keyword set if more are needed.\n        "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(actions)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            { staticClass: "manage-buttons" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "save-button",
                                  attrs: {
                                    "aria-label": "Save button",
                                    variant: "primary",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveChanges(data.item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.buttonText) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "delete-button",
                                  attrs: {
                                    "aria-label": "Delete button",
                                    variant: "danger",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showModal(data.item)
                                    },
                                  },
                                },
                                [_vm._v("\n            Remove\n          ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2031113737
                ),
              }),
              _c(
                "div",
                { staticClass: "add-keyword-button" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "success" },
                      on: { click: _vm.showKeywordModal },
                    },
                    [_vm._v("Add new Keywords")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("monitoring-no-identity", {
            attrs: { "identity-type": "Keywords", "model-solution": true },
            on: { "add-identity": _vm.showKeywordModal },
          }),
      _c(
        "b-modal",
        {
          ref: "add-keyword",
          attrs: { "hide-footer": "", title: "Add Keyword(s)" },
        },
        [
          _c("small", [_vm._v("Click Add or press enter to add keyword")]),
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addItem.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-tags", {
                    staticClass: "custom-form-tags",
                    attrs: {
                      separator: ",",
                      size: "lg",
                      limit: _vm.keywordLimit,
                      "limit-tags-text": "",
                    },
                    on: { limit: _vm.onLimitReached },
                    model: {
                      value: _vm.newItem.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.newItem, "value", $$v)
                      },
                      expression: "newItem.value",
                    },
                  }),
                  _vm.isLimitReached
                    ? _c("div", { staticClass: "text-danger mt-2" }, [
                        _vm._v(
                          "\n          Keyword limit reached. A total of " +
                            _vm._s(_vm.keywordLimit) +
                            " keywords can be used. \n          Please add new keyword set if more are needed.\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    "aria-label": "Add button",
                    disabled: _vm.newItem.value.length === 0,
                  },
                },
                [_vm._v("\n        Add\n      ")]
              ),
              _c(
                "b-button",
                {
                  attrs: { variant: "danger", "aria-label": "Cancel button" },
                  on: { click: _vm.hideKeywordModal },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "confirm-delete",
          attrs: {
            "hide-footer": "",
            title: "Are you sure you want to delete?",
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: {
                variant: "secondary",
                block: "",
                "aria-label": "Cancel button",
              },
              on: { click: _vm.hideModal },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: {
                variant: "danger",
                block: "",
                "aria-label": "Confirm button",
              },
              on: { click: _vm.deleteItem },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }